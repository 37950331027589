.leaflet-container {
    height: 25vw;
    /* resposive need it */
    width: 100% !important;
  }
  
  .leaflet-container {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .leaflet-container {
    background: #ddd;
    outline-offset: 1px;
}

